/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.gelpCx{
  overflow-y: auto !important;
}

.swal2-styled{
  margin-right: 5px !important;
}
.checkStyle {
  position: absolute;
  height: 60px;
  width: 60px;
  padding-top: 12px;
  z-index: 2;
  background-color: rgba(129, 190, 65, .9);
}
.checkStyle1 {
  position: absolute;
  height: 60px;
  width: 60px;
  padding-top: 12px;
  background-color: lightgray;
  z-index: 1;
}
.check1 {
  position: absolute;
  top: 17%;
  left: 6%;
  z-index: 1;
}
.class2{

  margin-top: 11px;
  position: absolute;
  top: 1%;
  left: -42%;
  background-color: transparent;
  z-index: 1;
  position: absolute;
}
.check3{
position: absolute;
top: 17%;
left: 4.6%;
  z-index: 1;
}
.switch-container {
  position: fixed;
  top: 80%;
  left: 20px; /* Adjust this value to position the switch where you want it */
  transform: translateY(-50%);
  z-index: 9999; /* Make sure the switch appears on top of other elements */
}
@font-face {
  font-family: 'Gotham Light';
  src: url('./../../assets/fonts/Gotham-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('./../../assets/fonts/Gotham-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'Gotham Light', sans-serif;
}
.banner-text{
  font-family: 'Gotham Bold', sans-serif;
}
.my-dataTable {
  min-height: 400px !important;
  overflow-y: auto !important;
}

.dateStyles{
  border-color: #cdc9c9 !important;
  border-radius: 4px !important;
  height: 37px;
  display: flex !important;
}

.custom-tooltip {
  background-color: white !important;
  color: black !important;
  border: 1px solid black;
  border-radius: 5px;
}

.heading1 {
  text-align: center !important;
}

.img-Container1{
  display: flex;
}

.imgMargin{
  margin-right: 13px;
}


@media screen and (max-width: 1360px) and (min-width: 1200px) {
  .returnBtn {
    font-size: 12px !important;
    padding: 8px !important;
    margin-left: 135px !important;
    margin-top: 32px !important;
  }
}

@media screen and (min-width: 1360px){
  .returnBtn {
    margin-top: 20px !important;
    margin-left: 150px !important;
  }
}

.mobileView{
  margin-bottom: -10px !important;
}

@media screen and (max-width: 770px) and (min-width: 300px) {
  .mobileView {
    font-size: 10px !important;
    line-height: 10px !important;
  }
}

.mobile-button {
  display: none; /* Initially hide the button */
}

@media (max-width: 800px) {
  /* Apply styles only for devices with a maximum width of 768px (adjust the value as needed) */
  .mobile-button {
    display: block; /* Show the button on mobile devices */
    position: fixed; /* Fix the button position */
    top: 150px; /* Adjust the bottom position as needed */
    left: 15px; /* Adjust the right position as needed */
    z-index: 9999; /* Adjust the z-index as needed to ensure it appears above other elements */
  }
}

.react-datetime-picker__clock {
  padding-left: 22px !important;
}

.react-datetime-picker__wrapper {
  padding: 8px !important;
  border-radius: 5px !important;
}

.css-1u57jws-control {
  min-width: 180px;
}

.__react_component_tooltip {
  width: auto;
}

.custom-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom-card-body {
  flex: 1;
}

.cardPadding {
  padding: 1rem 1rem !important;
}
